import React, { useContext } from "react"
import { PreviewContext } from "context/PreviewContext"
import { parseBlockContent } from "helpers/parseBlockContent"
import { RelatedPages } from "components/Project/RelatedPages"
import { Box, MainColumn, Spacer, Content, EmailSubscription } from "UI"
import Tags from "./Tags"
const PostDetailsTemplate = ({ data }) => {
  const { content, categories, date, modified } = data

  const { previewData } = useContext(PreviewContext)

  const parsedContent =
    previewData?.templateData?.content || content
      ? parseBlockContent(content)
      : null

  return (
    <Box mt={["-6px", null, "-22px"]}>
      <div>
        <MainColumn>
          <Box maxWidth="960px" m="0 auto">
            <Tags categories={categories} date={date} modified={modified} />
            <Spacer height={[24, null, 40]} />
          </Box>

          <Content>{parsedContent}</Content>
          <Spacer height={[10, 30, 65]} />
        </MainColumn>

        <EmailSubscription />
        <RelatedPages />

        <Box mb={[-10, -90, -120]} />
      </div>
    </Box>
  )
}

export default PostDetailsTemplate
