import React from "react"
import { Box, Text } from "UI"
import moment from "moment"

const Tags = ({ categories, date, modified }) => {
  const justPosted =
    moment(date).format("DD MMMM YYYY") ===
    moment(modified).format("DD MMMM YYYY")

  return (
    <Box
      direction="row"
      borderBottom="1px solid #47C5F3"
      pb={[17, 13]}
      alignItems="center"
    >
      <Text fontSize={[12, 14]} color="#008CD1">
        <strong>{categories.nodes[0].name}</strong>
      </Text>

      <Box
        background="#47C5F3"
        height="6px"
        width="6px"
        borderRadius="50%"
        m={["0 10px", "0 12px"]}
      />

      <Text fontSize={[12, 14]} color="#575A5E">
        <strong>
          {justPosted
            ? moment(date).format("DD MMMM YYYY")
            : `Last updated ${moment(modified).format("DD MMMM YYYY")}`}
        </strong>
      </Text>
    </Box>
  )
}

export default Tags
